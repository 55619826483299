import { isEmpty, omitBy } from "lodash";
import { useCallback } from "react";
import { useHistory } from "react-router";

import {
  HomeQueryParams,
  HomeQueryParamsKey,
  addHomeQueryParams,
  useQueryParamGetter,
} from "../../../../route/routes.js";
import { mapSearchParamsToHomeQueryParams } from "../../../../route/routeUtils.js";

/**
 * Updates the query parameters and concatenates the existing parameters with any new ones.
 *
 * This function is compatible with all pages that have Hexes because te path or subview is not changed.
 */
export const useUpdateParams = (): ((
  updatedParams: HomeQueryParams,
) => void) => {
  const getQueryParams = useQueryParamGetter();
  const history = useHistory();

  return useCallback(
    (updatedParams: HomeQueryParams) => {
      const {
        otherNonHomeQueryParams,
        searchParamsToHomeQueryParams: existingParams,
      } = mapSearchParamsToHomeQueryParams(
        Object.fromEntries(getQueryParams()) as Record<
          HomeQueryParamsKey,
          string
        >,
      );

      const baseParams = new URLSearchParams(otherNonHomeQueryParams);
      const queryParams = omitBy(
        {
          ...existingParams,
          ...updatedParams,
        },
        isEmpty,
      );
      history.push({
        search: addHomeQueryParams(queryParams, baseParams).toString(),
      });
    },
    [getQueryParams, history],
  );
};
