import { Classes, Intent } from "@blueprintjs/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { HexButton, HexPopover, HexTooltip } from "../../../../hex-components";
import { useToggleState } from "../../../../hooks/useToggleState";
import { ControlledContentEditable } from "../../../common/ControlledContentEditable";
import { EmojiItem, EmojiPicker } from "../../../common/emoji/EmojiPicker";
import { useToaster } from "../../../common/Toasts";
import { CollectionIcon } from "../../../icons/CustomIcons";
import { EditableNameWrapper } from "../../../logic/EditableNameWrapper";
import {
  TitleContentEditable,
  TitleLengthLimit,
} from "../../../logic/ProjectMetadataTitle.js";
import { TITLE_MAX_LENGTH } from "../../../logic/shared.js";

export const DEFAULT_COLLECTION_TITLE = "Untitled collection";

export interface UpdateCollectionMetadataProps {
  canEdit: boolean;
  onSave: (props: OnSaveCollectionMetadataArgs) => Promise<void> | void;
}

export interface OnSaveCollectionMetadataArgs {
  newTitle?: string;
  newEmoji?: string | null;
  newDescription?: string | null;
}

export interface CollectionNameProps {
  name: string;
  emoji?: string | null;
  small?: boolean;
  updateCollectionProps?: UpdateCollectionMetadataProps;
}

export const CollectionName: React.ComponentType<CollectionNameProps> =
  React.memo(function CollectionName({
    emoji,
    name,
    small = false,
    updateCollectionProps,
  }: CollectionNameProps) {
    const toaster = useToaster();
    const canEdit = updateCollectionProps?.canEdit;

    const nameRef = useRef<ControlledContentEditable>(null);
    const [currentTitle, _setCurrentTitle] = useState<string>(
      name !== "" ? name : DEFAULT_COLLECTION_TITLE,
    );

    const setCurrentTitle = useCallback(
      (title: string): void => {
        _setCurrentTitle(title ? title : DEFAULT_COLLECTION_TITLE);
      },
      [_setCurrentTitle],
    );

    const [editingTitle, setIsEditingTitle] = useState<boolean>(false);
    const [currentEmoji, setCurrentEmoji] = useState<string | null>(
      emoji || null,
    );

    // if the title or emoji changes from another editing dialog in the collections page, update here
    useEffect(() => {
      setCurrentTitle(name);
      if (emoji) {
        setCurrentEmoji(emoji);
      }
    }, [name, emoji, setCurrentTitle]);

    const save = useCallback(async (): Promise<void> => {
      setCurrentTitle(currentTitle);
      setIsEditingTitle(false);
      if (currentTitle.length > TITLE_MAX_LENGTH) {
        toaster.show({
          message: "Title exceeds maximum character limit.",
          intent: Intent.DANGER,
        });
        return;
      }
      await updateCollectionProps?.onSave({ newTitle: currentTitle });
    }, [currentTitle, setCurrentTitle, toaster, updateCollectionProps]);

    const [
      isEmojiPickerOpen,
      _,
      { setFalse: onCloseEmojiPicker, setTrue: onOpenEmojiPicker },
    ] = useToggleState(false);
    const onSelectEmoji = useCallback(
      async (emojiItem: EmojiItem) => {
        const content = emojiItem.native;
        setCurrentEmoji(content);
        onCloseEmojiPicker();
        await updateCollectionProps?.onSave({ newEmoji: content });
      },
      [onCloseEmojiPicker, updateCollectionProps],
    );

    // If the emoji dialog is closed without selecting an emoji, the state should be cleared and collection state updated
    const onClearEmojiCallback = useCallback(async () => {
      // only clear the emoji picker if it has not yet been saved, and its current state is null
      if (currentEmoji == null && currentEmoji !== emoji) {
        await updateCollectionProps?.onSave({ newEmoji: currentEmoji });
      }

      onCloseEmojiPicker();
    }, [currentEmoji, emoji, onCloseEmojiPicker, updateCollectionProps]);

    const clearEmojiAndOpenPicker = useCallback(() => {
      setCurrentEmoji(null);
      onOpenEmojiPicker();
    }, [onOpenEmojiPicker]);

    return canEdit ? (
      <TitleDiv>
        <Icon>
          <HexPopover
            content={<EmojiPicker onSelect={onSelectEmoji} />}
            isOpen={isEmojiPickerOpen}
            onClose={onClearEmojiCallback}
          >
            <HexTooltip content="Select an emoji" placement="bottom">
              {currentEmoji ? (
                <SelectedEmoji onClick={clearEmojiAndOpenPicker}>
                  {currentEmoji}
                </SelectedEmoji>
              ) : (
                <DefaultCollectionEmoji
                  icon={<CollectionIcon />}
                  minimal={true}
                  onClick={onOpenEmojiPicker}
                />
              )}
            </HexTooltip>
          </HexPopover>
        </Icon>
        <EditableNameWrapper size={small ? "default" : "page_title"}>
          <TitleContentEditable
            ref={nameRef}
            $editable={true}
            content={currentTitle}
            isEditing={editingTitle}
            maxLength={TITLE_MAX_LENGTH}
            onBlur={save}
            onCancel={save}
            onChange={setCurrentTitle}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={() => setIsEditingTitle(true)}
            onSave={save}
          />
          {editingTitle && (
            <TitleLengthLimit
              count={currentTitle.length}
              max={TITLE_MAX_LENGTH}
            />
          )}
        </EditableNameWrapper>
      </TitleDiv>
    ) : (
      <>
        <PageHeading $small={small}>
          {emoji && <CollectionEmoji $small={small}>{emoji}</CollectionEmoji>}
          <Title $small={small}>
            {name !== "" ? name : DEFAULT_COLLECTION_TITLE}
          </Title>
        </PageHeading>
      </>
    );
  });

const DefaultCollectionEmoji = styled(HexButton)`
  flex: none;
  width: 100%;
  height: 100%;

  transition: all ${({ theme }) => theme.animation.duration}
    ${({ theme }) => theme.animation.easing};

  &&&.${Classes.BUTTON} {
    &&.${Classes.BUTTON}:not(.${Classes.DISABLED}) {
      &:hover {
        box-shadow: inset 0 0 0 1px ${({ theme }) => theme.borderColor.DEFAULT};
      }
    }
  }
`;

export const CollectionEmoji = styled.div<{ $small: boolean }>`
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  width: ${({ $small }) => ($small ? "16px" : "34px")};
  height: ${({ $small }) => ($small ? "16px" : "34px")};

  font-size: ${({ $small, theme }) =>
    $small ? theme.fontSize.DEFAULT : theme.fontSize.HEADING_TWO};
`;

const SelectedEmoji = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  font-size: ${({ theme }) => theme.fontSize.EXTRA_LARGE};

  border-radius: ${({ theme }) => theme.borderRadius};

  cursor: pointer;

  transition: all ${({ theme }) => theme.animation.duration}
    ${({ theme }) => theme.animation.easing};

  .${Classes.ICON} > svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.hoverColor};
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.borderColor.DEFAULT};
  }
`;

const TitleDiv = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
  max-width: 600px;
`;

const Icon = styled.div`
  .${Classes.ICON} > svg {
    width: 20px;
    height: 20px;
  }
`;

const PageHeading = styled.div<{ $small: boolean }>`
  display: flex;
  gap: ${({ $small }) => ($small ? "5px" : "10px")};
  align-items: flex-start;

  font-weight: ${({ theme }) => theme.fontWeight.MEDIUM};
`;

const Title = styled.div<{ $small: boolean }>`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;

  font-size: ${({ $small, theme }) =>
    $small ? theme.fontSize.DEFAULT : theme.fontSize.HEADING_ONE};
  line-height: ${({ $small }) => ($small ? "16px" : "34px")};
`;
